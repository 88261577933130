import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: "smooth" };
  },
  routes: [
    {
      path: "/",
      name: "login",
      component: () => import("@/views/pages/Authentication/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/register",
      name: "Registration",
      component: () => import("@/views/pages/Authentication/Registration.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/forgotpassword",
      name: "ForgotPassword",
      component: () => import("@/views/pages/Authentication/ForgotPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/resetpassword",
      name: "ResetPassword",
      component: () => import("@/views/pages/Authentication/ResetPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/pages/StudentHome/Home.vue"),
      meta: {
        pageTitle: "Home",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    {
      path: "/agent_home",
      name: "AgentHome",
      component: () => import("@/views/pages/AgentHome/AgentHome.vue"),
      meta: {
        pageTitle: "Home",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    {
      path: "/staff_home",
      name: "StaffHome",
      component: () => import("@/views/pages/StaffHome/StaffHome.vue"),
      meta: {
        pageTitle: "Home",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    {
      path: "/school_counselor_home",
      name: "SchoolCounselorHome",
      component: () => import("@/views/pages/SchoolCounselorHome/SchoolCounselorHome.vue"),
      meta: {
        pageTitle: "Home",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    {
      path: "/accounts_home",
      name: "AccountsHome",
      component: () => import("@/views/pages/AccountsHome/AccountsHome.vue"),
      meta: {
        pageTitle: "Home",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    {
      path: "/training",
      name: "Training",
      component: () => import("@/views/pages/Trainings/Training.vue"),
      meta: {
        pageTitle: "Training",
        breadcrumb: [
          {
            text: "Training",
            active: true,
          },
        ],
      },
    },
    {
      path: "/training/:tr_id",
      name: "Training Details",
      component: () => import("@/views/pages/Trainings/TrainingDetail.vue"),
      meta: {
        pageTitle: "Training Details",
        breadcrumb: [
          {
            text: "Training Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/training-videos",
      name: "Franchise Training Videos",
      component: () => import("@/views/pages/AgentHome/Resources/FranchiseTrainingVideos/FranchiseTrainingsList.vue"),
      meta: {
        pageTitle: "Training Videos",
        breadcrumb: [
          {
            text: "Training Videos",
            active: true,
          },
        ],
      },
    },
    {
      path: "/training-videos/:ft_id",
      name: "Franchise Training Video Details",
      component: () => import("@/views/pages/AgentHome/Resources/FranchiseTrainingVideos/FranchiseTraining.vue"),
      meta: {
        pageTitle: "Training",
        showBack: true,
      },
    },
    {
      path: "/shop",
      name: "Shop",
      component: () => import("@/views/pages/Shop/Shop.vue"),
      meta: {
        pageTitle: "Shop",
        showBack: true,
      },
    },
    {
      path: "/shop/orders",
      name: "Shop Orders",
      component: () => import("@/views/pages/Shop/ShopOrders.vue"),
      meta: {
        pageTitle: "Shop Orders",
        showBack: true,
      },
    },
    {
      path: "/shop/cart",
      name: "Shop Cart",
      component: () => import("@/views/pages/Shop/ShopCart.vue"),
      meta: {
        pageTitle: "Shop Cart",
        showBack: true,
      },
    },
    {
      path: "/shop/checkout",
      name: "Shop Checkout",
      component: () => import("@/views/pages/Shop/ShopCheckout.vue"),
      meta: {
        pageTitle: "Shop Checkout",
        showBack: true,
      },
    },
    {
      path: "/marketing-insights",
      name: "MarketingInsights",
      component: () => import("@/views/pages/AgentHome/Resources/MarketInsights.vue"),
      meta: {
        pageTitle: "Marketing Insights",
        breadcrumb: [
          {
            text: "Marketing Insights",
            active: true,
          },
        ],
      },
    },
    {
      path: "/community-help",
      name: "CommunityHelp",
      component: () => import("@/views/pages/AgentHome/Resources/CommunityHelp.vue"),
      meta: {
        pageTitle: "Community Help",
        breadcrumb: [
          {
            text: "Community Help",
            active: true,
          },
        ],
      },
    },
    {
      path: "/sample_document",
      name: "SampleDocument",
      component: () => import("@/views/pages/AgentHome/Resources/SampleDocument.vue"),
      meta: {
        pageTitle: "Sample Document",
        breadcrumb: [
          {
            text: "Sample Document",
            active: true,
          },
        ],
      },
    },
    {
      path: "/test",
      name: "Test",
      component: () => import("@/views/pages/AgentHome/Resources/Test.vue"),
      meta: {
        pageTitle: "Test",
        breadcrumb: [
          {
            text: "Test",
            active: true,
          },
        ],
      },
    },
    {
      path: "/application",
      name: "application",
      component: () => import("@/views/pages/Application/Application.vue"),
      meta: {
        pageTitle: "Application",
        breadcrumb: [
          {
            text: "Application",
            active: true,
          },
        ],
      },
    },
    {
      path: "/student-support",
      name: "StudentSupport",
      component: () => import("@/views/pages/StudentSupports/StudentSupportList.vue"),
      meta: {
        pageTitle: "Student Supports",
        breadcrumb: [
          {
            text: "Student Supports",
            active: true,
          },
        ],
      },
    },
    {
      path: "/universitysearch",
      name: "University Search",
      component: () => import("@/views/pages/University Search/universitySearch.vue"),
      // component: () => import('@/views/pages/Course Detail/About.vue'),

      meta: {
        pageTitle: "University",
        breadcrumb: [
          {
            text: "University Search",
            active: true,
          },
        ],
      },
    },
    {
      path: "/university-usp",
      name: "University USP",
      component: () => import("@/views/pages/University USP/UniversityUSP.vue"),
      // component: () => import('@/views/pages/Course Detail/About.vue'),

      meta: {
        pageTitle: "University USP",
        breadcrumb: [
          {
            text: "University USP",
            active: true,
          },
        ],
      },
    },
    {
      path: "/university-detail/:university_id",
      name: "University Detail",
      component: () => import("@/views/pages/University Search/University Detail/About.vue"),
      meta: {
        pageTitle: "University Detail",
        breadcrumb: [
          {
            text: "University Detail",
            active: true,
          },
        ],
      },
    },
    {
      path: "/profile_form",
      name: "profile_form",
      component: () => import("@/views/pages/Profile_Form/Profile_Form.vue"),
      meta: {
        pageTitle: "Profile Form",
        breadcrumb: [
          {
            text: "Profile",
            active: true,
          },
        ],
      },
    },
    {
      path: "/documents",
      name: "documents",
      component: () => import("@/views/pages/Documents/Documents.vue"),
      meta: {
        pageTitle: "Documents",
        breadcrumb: [
          {
            text: "documents",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tasks",
      name: "apps-todo",
      component: () => import("@/views/pages/Tasks/Todo.vue"),
      meta: {
        contentRenderer: "sidebar-left",
        contentClass: "todo-application",
      },
    },
    {
      path: "/tasks-assigned",
      name: "tasks-assigned",
      component: () => import("@/views/pages/Tasks/Todo.vue"),
      meta: {
        contentRenderer: "sidebar-left",
        contentClass: "todo-application",
      },
    },
    {
      path: "/tasks-important",
      name: "tasks-important",
      component: () => import("@/views/pages/Tasks/Todo.vue"),
      meta: {
        contentRenderer: "sidebar-left",
        contentClass: "todo-application",
      },
    },
    {
      path: "/tasks-completed",
      name: "tasks-completed",
      component: () => import("@/views/pages/Tasks/Todo.vue"),
      meta: {
        contentRenderer: "sidebar-left",
        contentClass: "todo-application",
      },
    },
    {
      path: "/tasks-deleted",
      name: "tasks-deleted",
      component: () => import("@/views/pages/Tasks/Todo.vue"),
      meta: {
        contentRenderer: "sidebar-left",
        contentClass: "todo-application",
      },
    },
    {
      path: "/tasks/:filter",
      name: "apps-todo-filter",
      component: () => import("@/views/pages/Tasks/Todo.vue"),
      meta: {
        contentRenderer: "sidebar-left",
        contentClass: "todo-application",
        navActiveLink: "apps-todo",
      },
      beforeEnter(to, _, next) {
        if (["important", "completed", "deleted"].includes(to.params.filter)) next();
        else next({ name: "error-404" });
      },
    },
    {
      path: "/tasks/tag/:tag",
      name: "apps-todo-tag",
      component: () => import("@/views/pages/Tasks/Todo.vue"),
      meta: {
        contentRenderer: "sidebar-left",
        contentClass: "todo-application",
        navActiveLink: "apps-todo",
      },
      beforeEnter(to, _, next) {
        if (["low", "medium", "high"].includes(to.params.tag)) next();
        else next({ name: "error-404" });
      },
    },

    {
      path: "/services",
      name: "services",
      component: () => import("@/views/pages/Services/Services.vue"),
      meta: {
        pageTitle: "Services",
        breadcrumb: [
          {
            text: "Services",
            active: true,
          },
        ],
      },
      props: (route) => ({ type: route.query.type }),
    },
    {
      path: "/chat",
      name: "chat",
      component: () => import("@/views/pages/Chat/Chat.vue"),
      meta: {
        // pageTitle: 'Services',

        contentRenderer: "sidebar-left",
        contentClass: "chat-application",
      },
    },
    {
      path: "/Settings",
      name: "Settings",
      component: () => import("@/views/pages/Settings/Settings.vue"),
      meta: {
        pageTitle: "Settings",
        breadcrumb: [
          {
            text: "Settings",
            active: true,
          },
        ],
      },
    },
    {
      path: "/student_details/:student_user_id",
      name: "Student Details",
      component: () => import("@/views/pages/Student_Details/Details.vue"),
      meta: {
        pageTitle: "Student Details",
        showBack: true,
        breadcrumb: [
          {
            text: "Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/student_list",
      name: "Student List",
      component: () => import("@/views/pages/Student List/StudentList.vue"),
      meta: {
        pageTitle: "Student List",
        breadcrumb: [
          {
            text: "Student List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/student_applications",
      name: "Student Applications",
      component: () => import("@/views/pages/StudentApplications/StudentApplications.vue"),
      meta: {
        pageTitle: "Student Applications",
        breadcrumb: [
          {
            text: "Student Applications",
            active: true,
          },
        ],
      },
    },
    {
      path: "/Announcements",
      name: "Announcements",
      component: () => import("@/views/pages/Announcements/Announcements.vue"),
      meta: {
        contentRenderer: "sidebar-left",
        contentClass: "email-application",
      },
    },
    {
      path: "/assigned_agents",
      name: "Assigned Agents",
      component: () => import("@/views/pages/AssignedAgents/AssignedAgents.vue"),
      meta: {
        pageTitle: "Assigned Agents",
        breadcrumb: [
          {
            text: "Assigned Agents",
            active: true,
          },
        ],
      },
    },
    {
      path: "/notifications",
      name: "Notifications",
      component: () => import("@/views/pages/Notifications/Notifications.vue"),
      meta: {
        pageTitle: "Notifications",
        breadcrumb: [
          {
            text: "Notifications",
            active: true,
          },
        ],
      },
    },
    {
      path: "/university-commissions",
      name: "UniversityCommissions",
      component: () => import("@/views/pages/University_Commissions/UniCommissionPage.vue"),
      meta: {
        pageTitle: "University Commissions",
        breadcrumb: [
          {
            text: "University Commissions",
            active: true,
          },
        ],
      },
    },
    {
      path: "/university-commissions/:university_id",
      name: "Commission Details",
      component: () => import("@/views/pages/University_Commissions/CommissionDetail.vue"),
      meta: {
        pageTitle: "University Commissions",
        showBack: true,
        breadcrumb: [
          {
            text: "Details",
            active: true,
          },
        ],
      },
    },
    // {
    //   path: "/leads",
    //   name: "Leads",
    //   component: () => import("@/views/pages/StudentLeads/LeadsList.vue"),
    //   meta: {
    //     pageTitle: "Leads",
    //     breadcrumb: [
    //       {
    //         text: "Leads",
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: "/my_leads",
      name: "My Leads",
      component: () => import("@/views/pages/MyLeads/MyLeads.vue"),
      meta: {
        pageTitle: "My Leads",
        breadcrumb: [
          {
            text: "My Leads",
            active: true,
          },
        ],
      },
    },
    {
      path: "/my_leads/lead_form",
      name: "Lead Form",
      component: () => import("@/views/pages/MyLeads/LeadForm.vue"),
      meta: {
        pageTitle: "Lead Form",
        showBack: true,
        breadcrumb: [
          {
            text: "Lead Form",
            active: true,
          },
        ],
      },
    },
    {
      path: "/transactions",
      name: "Transactions",
      component: () => import("@/views/pages/Transactions/Transactions.vue"),
      meta: {
        pageTitle: "Transactions",
        breadcrumb: [
          {
            text: "Transactions",
            active: true,
          },
        ],
      },
    },
    {
      path: "/shop_orders",
      name: "Accounts Shop Orders",
      component: () => import("@/views/pages/AccountOrders/Orders.vue"),
      meta: {
        pageTitle: "Shop Orders",
        breadcrumb: [
          {
            text: "Orders",
            active: true,
          },
        ],
      },
    },
    {
      path: "/shop_order_details/:so_id",
      name: "Shop Order Details",
      component: () => import("@/views/pages/Shop/OrderDetails.vue"),
      meta: {
        pageTitle: "Shop Order Details",
        breadcrumb: [
          {
            text: "Order Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/my_leads/:lead_id",
      name: "Lead Details",
      component: () => import("@/views/pages/MyLeads/LeadDetails.vue"),
      meta: {
        pageTitle: "Lead Details",
        showBack: true,
        breadcrumb: [
          {
            text: "Lead Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cluster-profile",
      name: "ClusterProfile",
      component: () => import("@/views/pages/Cluster/ClusterProfileCard.vue"),
      meta: {
        pageTitle: "Cluster Profile",
        showBack: true,
        breadcrumb: [
          {
            text: "Cluster Profile",
            active: true,
          },
        ],
      },
    },
    {
      path: "/terms-and-conditions",
      name: "Terms And Conditions",
      component: () => import("@/views/pages/TermsAndConditions.vue"),
      meta: {
        pageTitle: "Terms And Conditions",
        breadcrumb: [
          {
            text: "Terms And Conditions",
            active: true,
          },
        ],
      },
    },
    {
      path: "/privacy-policy",
      name: "Privacy Policy",
      component: () => import("@/views/pages/PrivacyPolicy.vue"),
      meta: {
        pageTitle: "Privacy Policy",
        breadcrumb: [
          {
            text: "Privacy Policy",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cancellation-and-refund-policy",
      name: "Cancellation",
      component: () => import("@/views/pages/CancellationRefundPolicy.vue"),
      meta: {
        pageTitle: "Cancellation and Refund policy",
        breadcrumb: [
          {
            text: " Cancellation and Refund policy",
            active: true,
          },
        ],
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
