// Switz Theme
// const theme = {
//   primary: "#ff0000",
//   secondary: "#808080",
//   success: "#00d09c",
//   info: " #00cfe8",
//   warning: "#ffa132",
//   light: "#f6f6f6",
//   dark: "#4b4b4b",
//   danger: "#ea5455",
//   "menu-secondary": "#808080",
//   "primary-1": "#ff0000",
//   "primary-2": "#ff5e5e",
//   "primary-1-light-1": "#ff6c6c",
//   "primary-1-light-2": "#ff9090",
//   "primary-1-light-3": "#ffe4e4",
// };

// Aliff Theme
const theme = {
  primary: "#5367ff",
  secondary: "#82868b",
  success: "#00d09c",
  info: "#00cfe8",
  warning: "#ffa132",
  light: "#f6f6f6",
  dark: "#4b4b4b",
  danger: "#ea5455",
  "menu-secondary": "#00d09c",
  "primary-1": "#5367ff",
  "primary-2": "#00d09c",
  "primary-1-light-1": "#7888ff",
  "primary-1-light-2": "#96a2ff",
  "primary-1-light-3": "#e2e5ff",
};

function injectThemeVariables(variables) {
  let cssVariables = ":root {\n";
  for (const [key, value] of Object.entries(variables)) {
    cssVariables += `--${key}: ${value};\n`;
  }
  cssVariables += "}";

  const style = document.createElement("style");
  style.innerHTML = cssVariables;
  document.head.appendChild(style);
}

// Inject the theme variables

export const selectedTheme = theme;

export default function addTheme() {
  injectThemeVariables(selectedTheme);
}
