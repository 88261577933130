import axiosInstance from './Api';

export default {

    async getSearchList(payload) {

        let response = await axiosInstance().get(`/home/search/list`, { params: payload });

        return response;
    },

    async getUniversity(payload) {
        const { university_id } = payload;

        let response = await axiosInstance().get(`/home/university/${university_id}`);

        return response;
    },

    async getCountries() {

        let response = await axiosInstance().get(`/home/countries-and-cities`);

        return response;
    },

    async getAllCountries(payload) {

        let response = await axiosInstance().get(`/home/countries`, { params: payload });

        return response;
    },

    async getAllStates(payload) {

        let response = await axiosInstance().get(`/home/states`, { params: payload });

        return response;
    },

    async getAllCities(payload) {

        let response = await axiosInstance().get(`/home/cities`, { params: payload });

        return response;
    },

    async getCourseLevels() {

        let response = await axiosInstance().get(`/home/course-levels`);

        return response;
    },

    async getCourseCategories() {

        let response = await axiosInstance().get(`/home/course-categories`);

        return response;
    },

    async getTuitionFeesFilterValues() {

        let response = await axiosInstance().get(`/home/tution-fees-filter-values`);

        return response;
    },

    async getTuitionUSDFeesFilterValues() {

        let response = await axiosInstance().get(`/home/tution-fees-filter-values-usd`);

        return response;
    },

    async getServicesAndPlans(payload) {

        let response = await axiosInstance().get(`/home/services-and-plans`, { params: payload });

        return response;
    },

    async getAgentInfo(payload) {
        const { agent_user_id } = payload;

        let response = await axiosInstance().get(`/home/agent-info/${agent_user_id}`);

        return response;
    },

    async getSchoolCounselorInfo(payload) {
        const { sc_user_id } = payload;

        let response = await axiosInstance().get(`/home/sc-info/${sc_user_id}`);

        return response;
    },

}